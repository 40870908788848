import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthHelper } from '@shared/helpers/authHelper.provider';
import { map, skipWhile, take, tap } from 'rxjs/operators';
import { Permission } from '@shared/Entities/Auth';

@Injectable()
export class AgencyGuard implements CanActivate {

  constructor(
    private authHelper: AuthHelper,
    private router: Router
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.authHelper
      .isLoggedIn()
      .then(result => {
        if (!result) {
          this.authHelper.login(state.url);
          return false;
        }
        return this.authHelper.authContext
          .pipe(
            skipWhile(v => !v),
            take(1),
            map(context => context.isAgency && this.authHelper.checkForPermissions(context.userProfile.UserPermissions)),
            tap(valid => {
              if (!valid) {
                this.router.navigateByUrl('unauthorized');
              }
            })
          )
          .toPromise();
      });
  }
}
