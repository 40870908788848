<div
  class="dark-theme px3 pt2"
  style="color: white; background: black; height: 100vh; width: 100vw;">
  <h1>Unauthorized</h1>
  <p
    class="mat-body">
    You do not have permission to perform that action.
  </p>
  <p>
    <a
      href="javascript:void(0)"
      mat-stroked-button
      color="primary"
      (click)="logout()"
      class="">
      Try logging out and logging back in.
    </a>
  </p>
</div>

