import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { PermissionAction } from '@shared/Entities/Auth/Enums/permission-action';
import { PermissionEntity } from '@shared/Entities/Auth/Enums/permission-entity';
import { AuthHelper } from '@shared/helpers/authHelper.provider';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private authHelper: AuthHelper,
    private router: Router
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const access = next.data.access as { entity: PermissionEntity; action: PermissionAction }[];
    const activate = this.authHelper.checkPermissionAccess(access);
    if (!activate && next.data.fallbackTo) {
      this.router.navigate([next.data.fallbackTo]);
    }
    return activate;
  }
}
