import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from '@shared/components/login/login.component';
import { CarrierGuard } from '@shared/guards/carrierGuard.provider';
import { AgencyGuard } from '@shared/guards/agencyGuard.provider';
import { MyMaterialModule } from './material.module';
import { AdminGuard } from '@shared/guards/adminGuard.provider';
import { ErrorComponent } from '@shared/components/error/error.component';
import { PendingChangesGuard } from '@shared/guards/pendingChangesGuard.provider';
import { ConfirmationDialogComponent } from '@shared/components/system/dialogs/confirmation-dialog.component';
import { SelectDocumentTypeDialogComponent } from '@shared/components/system/dialogs/select-document-type-dialog.component';
import { SigninRedirectCallbackComponent } from './home/signin-redirect-callback.component';
import { SignoutRedirectCallbackComponent } from './home/signout-redirect-callback.component';
import { UnauthorizedComponent } from './home/unauthorized.component';
import { AuthInterceptorService } from './shared/httpInterceptors/authInterceptor';
import { DateInterceptorService } from './shared/httpInterceptors/dateInterceptor';
import { CurrencyPipe } from '@angular/common';
import { AuthGuard } from '@shared/guards/authGuard.provider';
import * as Sentry from '@sentry/angular';

@NgModule({
  imports: [
    MyMaterialModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
  ],
  declarations: [
    AppComponent,
    LoginComponent,
    SigninRedirectCallbackComponent,
    SignoutRedirectCallbackComponent,
    UnauthorizedComponent,
    ErrorComponent,
    ConfirmationDialogComponent,
    SelectDocumentTypeDialogComponent,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    CarrierGuard,
    AgencyGuard,
    AuthGuard,
    AdminGuard,
    PendingChangesGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DateInterceptorService,
      multi: true,
    },
    [CurrencyPipe],
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
