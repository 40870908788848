import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { from, lastValueFrom, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthHelper } from '../helpers/authHelper.provider';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  constructor(private _authService: AuthHelper,
    private _router: Router) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this._authService.getAccessToken()
      .then(token => {
        if (!token) {
          this._authService.login();
        }
        const headers = new HttpHeaders()
          .set('Authorization', `Bearer ${token}`)
          .set('Content-Type', 'application/json');
        const authReq = req.clone({ headers });
        return lastValueFrom(next.handle(authReq)
          .pipe(
            tap({
              error: error => {
                const respError = error as HttpErrorResponse;
                if (respError && (respError.status === 401)) {
                  this._router.navigate(['unauthorized']);
                }
              }
            })
          ));
      }));
  }
}
