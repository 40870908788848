import { Component, OnInit, OnDestroy } from '@angular/core';
import { ErrorService } from '../../services/errorService.provider';
import { Subscription } from 'rxjs';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html'
})
export class ErrorComponent implements OnInit, OnDestroy {

  private subscription: Subscription = new Subscription();

  constructor(
    private _snackBar: MatSnackBar,
    private errorService: ErrorService
  ) { }

  ngOnInit() {
    this.subscription = this.errorService.subjectError.subscribe(error => {
      this._snackBar.open(error, null, { duration: 5000, panelClass: ['color-warn', 'error'] } as MatSnackBarConfig);
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
