import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from "@sentry/angular";
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

Sentry.init({
  dsn: "https://291d04cdd2c94ea9821c182f0966c00e@o430415.ingest.sentry.io/5378912",
  environment: environment.sentryEnvironment,
  integrations: [
  ],
  ignoreErrors: [
    '403 Forbidden',
    '403 OK',
    '401 Unauthorized',
    '401 OK',
    'Non-Error exception'
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
