export const environment = {
  production: true,
  sentryEnvironment: 'develop',
  clientId: 'vtg-ui',
  clientRoot: 'https://poc.vertigocomp.com/',
  stsAuthority: 'https://poc.verishell.com/idp/',
  baseSubmissionUrl: 'https://poc.verishell.com/api/submission/',
  baseVectorUrl: 'https://poc.verishell.com/api/vector/',
  baseRateEngineUrl: 'https://poc.verishell.com/api/ratingengine/',
  baseNotificationUrl: 'https://poc.verishell.com/api/notification/',
  baseTaskUrl: 'https://poc.verishell.com/api/task/',
  baseCompanyUrl: 'https://poc.verishell.com/api/company/',
  baseResourceUrl: 'https://poc.verishell.com/api/resource/',
  baseAuthUrl: 'https://poc.verishell.com/api/auth/',
  baseNoteUrl: 'https://poc.verishell.com/api/note/',
  basePhotoBlobUrl: 'https://vtgdeidpreprodstorage.blob.core.windows.net/pictures/',
  baseMapTilesBlobUrl: 'https://vtgdeidpreprodstorage.blob.core.windows.net/map-tiles/tilesWData/',
  baseVeriShellCarrierUrl: 'https://alpha.verishell.com/carrier/'
};
