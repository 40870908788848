import { Component } from '@angular/core';
import { AuthHelper } from '@shared/helpers/authHelper.provider';

@Component({
  selector: 'app-unauthorized',
  templateUrl: 'unauthorized.component.html'
})

export class UnauthorizedComponent {
  constructor(private _authService: AuthHelper) { }
  logout() {
    this._authService.logout();
  }
}
