import { CanDeactivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { map, Observable, take } from 'rxjs';

export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable()
export class PendingChangesGuard implements CanDeactivate<ComponentCanDeactivate> {
  canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean> {
    if (!component) {
      return true;
    }
    // if there are no pending changes, just allow deactivation; else confirm first
    if (component.canDeactivate) {
      const result = component.canDeactivate();
      if (result instanceof Observable) {
        return result
          .pipe(
            take(1),
            map(can => {
              if (can) {
                return true;
              }
              return confirm('You have unsaved changes. Press Cancel to go back and save these changes, or OK to lose these changes.');
            })
          );
      } else if (!result) {
        return confirm('You have unsaved changes. Press Cancel to go back and save these changes, or OK to lose these changes.');
      }
    }
    return true;
  }
}
